import { Injectable } from '@angular/core';
import { RequestService } from '../services/request.service';
import { MedicalExamDateInfo, MedicalExamResult } from '../models/medical-exam-result.model';
import * as moment from 'moment';
import { Task } from '../models/task.model';
import { Patient } from '../models/patient.model';

@Injectable({
  providedIn: 'root'
})
export class PatientService {

  constructor(private requestService: RequestService) { }

  createTask(task: any): Promise<void> {
    return this.requestService.post('/taskScheduler', task)
  }

  setPatientNeedsMonitoring(patientId: string, diagnosis: string): Promise<void> {
    return this.requestService.put(`/patient/${patientId}/needsMonitoring?diagnosis=${diagnosis}`, {})
  }

  getActivePatientTasks(patientId: string): Promise<Task[]> {
    const activeTasksStatus = "NOT_STARTED,RUNNING";
    return this.requestService.get(`/taskScheduler?status=${activeTasksStatus}&filterBy=patient&filterValue=${patientId}`);
  }

  getPatientFiles(patientId: string): Promise<MedicalExamResult[]> {
    return this.requestService.get(`/patient/${patientId}/medicalExamResult`)
  }

  async getPatientFilesByDate(patientId: string): Promise<MedicalExamDateInfo[]> {
    const medicalExamResults = await this.getPatientFiles(patientId);
    return this.groupMedicalExamsByDate(medicalExamResults);
  }

  groupMedicalExamsByDate(medicalExamResults: MedicalExamResult[]): MedicalExamDateInfo[] {
    const medicalExamsDateInfo: MedicalExamDateInfo[] = [];

    medicalExamResults.forEach(item => {
      const date = moment(item.date || item.createdAt).format("DD/MM/YYYY");

      const index = medicalExamsDateInfo.findIndex(el => date === el.date)
      if(index !== -1) {
        // Date found
        medicalExamsDateInfo[index].files.push(item);
      } else {
        medicalExamsDateInfo.push({date, files: [item]});
      }
    });

    return medicalExamsDateInfo.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
  }

  getFileUrl(patientId: string, fileId: string): Promise<{url: string}> {
    return this.requestService.get(`/patient/${patientId}/getFileUrl/${fileId}`);
  }

  getEmail(patient: Patient): string {
    return patient.emailLAD || patient.secondaryEmail || patient.email;
  }
}
