import { Component, inject, OnInit } from '@angular/core';
import { MedicalHistoryService } from '../../services/medical-history.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AmplitudeService } from '../../services/amplitude.service';
import { AppPaths } from '../../enums/app-paths.enum';
import {LocaleService} from "../../services/locale.service";

@Component({
  selector: 'app-confirmation-sending-hc',
  template: '',
  styles: [''],
})
export class SendMedicalRecordsComponent implements OnInit {
  private medicalHistoryService = inject(MedicalHistoryService);
  private readonly router = inject(Router);
  private readonly route = inject(ActivatedRoute);
  private readonly amplitudeService = inject(AmplitudeService);
  private readonly localeService = inject(LocaleService);

  errorToSendingEmail = false;

  ngOnInit(): void {
    this.route.queryParams.subscribe(async (params) => {
      const patient = params?.patient;
      const provider = params?.provider;
      const locale = params?.locale;
      if (locale) {
        await this.localeService.use(locale);
      }
      if (!patient) {
        return;
      }
      this.amplitudeService.setUser(patient, provider);
      try {
        const { result } =
          await this.medicalHistoryService.sendPatientMedicalRecords(
            params.patient,
          );
        if (result) {
          this.amplitudeService.logEvent(
            'medical_records_accepted_and_email_sent',
          );
        }
        this.goToMessage('medical_records_email_greeting');
      } catch (error) {
        this.errorToSendingEmail = true;
        if (error.error.status === 401) {
          this.goToMessage('medical_records_token_expired_message');
          return;
        }
        this.goToMessage(error?.error?.message);
      }
    });
  }

  goToMessage(message: string): void {
    this.router.navigate([AppPaths.MESSAGE], {
      state: { message },
    });
  }
}
