// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_URL: 'https://dev.api.llamandoaldoctor.com',
  DAY_TIMESPAN_IN_MILIS: 86400000, // 24h
  PRESCRYPTO: {
    HOSPITAL_ID: 197,
    URL: 'https://integration.prescrypto.com'
  },
  AMPLITUDE_API_KEY: '6f376593d1b466625de64efda5a4f5ca',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
