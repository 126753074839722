import {inject, Injectable} from '@angular/core';
import { LocaleService } from './locale.service';
import { RequestService } from './request.service';

@Injectable({
  providedIn: 'root'
})
export class MedicalHistoryService {
  private readonly localeService = inject(LocaleService);
  private readonly requestService = inject(RequestService);

  sendPatientMedicalRecords(id: string): Promise<{ result: string }> {
    return this.requestService.post(`/email/patient/${id}/sendMedicalRecords`, {});
  }

  getMedicalHistory(records: string[]): string {
    if(!records) return;
    const medicalRecords = this.getLocalizedMedicalRecords();
    let medicalHistory = "";
    records.forEach(record => {
      medicalHistory += medicalRecords[record] + ", "
    });
    return medicalHistory.slice(0, -2);
  }

  private getLocalizedMedicalRecords(): string[] {
    switch (this.localeService.getLocale()) {
      case "pt":
      case "pt-br":
        return [
          "Eu não apresento nenhum antecedente",
          "Hipertensão arterial",
          "Diabetes",
          "Hipo/Hipertiroidismo",
          "Tabagismo",
          "Alcoolismo",
          "Drogas",
          "Alergia a medicamentos",
          "Acidente vascular cerebral",
          "Outras convulsões/Epilepsia",
          "Enxaqueca/Cefaleia",
          "Comprometimento Cognitivo",
          "Enfarte",
          "Arritmias",
          "Colocação de stent",
          "Cirurgia cardiovascular",
          "Marcapasso",
          "Asma",
          "Enfisema pulmonar",
          "Hemorragia digestiva",
          "Gastrite/Úlcera gástrica",
          "Hepatite",
          "Insuficiência hepática",
          "Cirrose",
          "Distúrbios da coagulação",
          "Linfoma/Leucemia",
          "Neutropenia",
          "Doenças sexualmente transmissíveis",
          "Tuberculose",
          "Doenças oncológicas",
          "Doenças congênitas/hereditárias",
          "Cirurgias prévias",
          "Anticoagulação",
          "Quimioterapia",
          "Corticóides",
          "Baixo peso",
          "Excesso de peso",
          "Recém-nascido prematuro",
          "Doenças durante a gravidez",
          "Doença genética",
          "Convulsões febris",
          "Atraso maturativo",
          "Cardiopatia congênita",
          "Broncoespasmo de repetição",
          "Alergia ao leite de vaca",
          "Doença celíaca",
          "Imunodeficiências",
        ]
      case 'en':
      case 'en-us':
        return [
          "No past medical history",
          "Hypertension",
          "Diabetes",
          "Hypo/Hyperthyroidism",
          "Smoking",
          "Alcoholism",
          "Drugs",
          "Medication allergy",
          "Cerebrovascular accident",
          "Other seizure/Epilepsy",
          "Migraine/Headache",
          "Cognitive impairment",
          "Heart attack",
          "Arrhythmias",
          "Stent implantation",
          "Cardiovascular surgery",
          "Pacemaker",
          "Asthma",
          "Pulmonary emphysema",
          "Gastrointestinal Bleeding",
          "Gastritis/Gastric ulcer",
          "Hepatitis",
          "Liver Failure",
          "Cirrhosis",
          "Coagulation disorders",
          "Leukemia/Lymphoma",
          "Neutropenia",
          "Sexually transmitted diseases",
          "Tuberculosis",
          "Oncological diseases",
          "Congenital/Hereditary disorders",
          "Previous surgeries",
          "Anticoagulation",
          "Chemotherapy",
          "Corticosteroids",
          "Underweight",
          "Overweight",
          "Premature infant",
          "Infections during pregnancy",
          "Genetic disease",
          "Febrile seizure",
          "Maturational delay",
          "Congenital heart disease",
          "Repeated bronchospasms",
          "Cow's milk allergy",
          "Celiac disease",
          "Immunodeficiency disorders",
        ]
      default:
        return [
          "No presento antecedentes patológicos",
          "Hipertensión arterial",
          "Diabetes",
          "Hipo/Hipertiroidismo",
          "Tabaquismo",
          "Alcoholismo",
          "Drogas",
          "Alergia a medicamentos",
          "Accidente cerebrovascular",
          "Otras Convulsiones/Epilepsia",
          "Migraña/Cefalea",
          "Deterioro cognitivo",
          "Infarto",
          "Arritmias",
          "Colocación de stents",
          "Cirugía cardiovascular",
          "Marcapasos",
          "Asma",
          "Enfisema pulmonar",
          "Hemorragia digestiva",
          "Gastritis/Úlcera gástrica",
          "Hepatitis",
          "Insuficiencia hepática",
          "Cirrosis",
          "Trastornos de la coagulación",
          "Linfoma/Leucemia",
          "Neutropenia",
          "Enfermedades de transmición sexual",
          "Tuberculosis",
          "Enf. Oncológicas",
          "Enfermedades Congénitas/Hereditarias",
          "Cirugías previas",
          "Anticoagulación",
          "Quimioterapia",
          "Corticoides",
          "Bajo peso",
          "Sobrepeso",
          "Recién nacido prematuro",
          "Infecciones durante el embarazo",
          "Enfermedad genética",
          "Convulsiones febriles",
          "Retraso madurativo",
          "Cardiopatía congénita",
          "Broncoespasmos a repetición",
          "Alergia a la leche de vaca",
          "Enfermedad celíaca",
          "Inmunodeficiencias",
        ]
    }
  }
}
