import { Injectable } from '@angular/core';
import * as amplitude from '@amplitude/analytics-browser';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AmplitudeService {
  private amplitudeInitialized = false;

  constructor() {
    this.initializeAmplitude();
  }

  private initializeAmplitude(): void {
    amplitude.init(environment.AMPLITUDE_API_KEY);
    this.amplitudeInitialized = true;
  }

  public logEvent(
    eventName: string,
    eventProperties?: { [key: string]: any },
  ): void {
    if (!this.amplitudeInitialized) {
      console.warn('Amplitude has not been initialized.');
      return;
    }
    amplitude.logEvent(eventName, eventProperties);
  }

  public setUser(userId: string, provider?: string): void {
    if (!this.amplitudeInitialized) {
      console.warn('Amplitude has not been initialized.');
      return;
    }
    amplitude.setUserId(userId);
    if (provider) {
      const identify = new amplitude.Identify();
      identify.set('provider', provider);
      amplitude.identify(identify);
    }
  }
}
