import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CallService } from 'src/app/services/call.service'
import { LocaleService } from 'src/app/services/locale.service'
import { SharedDataService } from 'src/app/services/shared-data.service'
import { DiagnosisService } from 'src/app/services/diagnosis.service';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MediaMatcher } from '@angular/cdk/layout';
import { Title } from '@angular/platform-browser';
import { NotificationsService } from 'src/app/services/notifications.service'
import { AppPaths } from 'src/app/enums/app-paths.enum';
import { Call } from 'src/app/models/call.model';
import { PatientService } from 'src/app/services/patient.service';
import { MedicalExamDateInfo } from 'src/app/models/medical-exam-result.model';
import { MEDICAL_EXAM_TYPES } from 'src/app/consts/medical-exam-types.const';
import * as _ from 'lodash';
import { DoctorService } from 'src/app/services/doctor.service';

export enum VisualizationOptions {
  CALL_FORM = 'call_form',
  MEDICAL_RECORDS = 'medical_records',
  MEDICAL_EXAMS = 'medical_exams',
}

@Component({
  selector: 'app-doctor',
  templateUrl: './doctor.component.html',
  styleUrls: ['./doctor.component.scss'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false }
  }],
})
export class DoctorComponent implements OnInit, OnDestroy {
  call: Call;
  loading = true;
  diagnosisComplete = false;
  canPrescribeMedication: boolean;
  canMakeMedicalOrderInstruction: boolean;

  patientCalls: {data: Call[], total: number} = {
    data: [],
    total: 0,
  }
  filesByDates: MedicalExamDateInfo[];
  previousRecords?: string[];

  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  readonly VisualizationOptions = VisualizationOptions;
  tabBarOptions = [
    {
      option: VisualizationOptions.CALL_FORM,
      text: 'tab_header_info',
    },
    {
      option: VisualizationOptions.MEDICAL_RECORDS,
      text: 'tab_header_medical_records',
    },
    {
      option: VisualizationOptions.MEDICAL_EXAMS,
      text: 'tab_header_medical_exams',
    }
  ];
  optionSelected: string = VisualizationOptions.CALL_FORM;
  tabGroupInfoIndex = 0;

  constructor(
    private router: Router,
    private callService: CallService,
    private sharedDataService: SharedDataService,
    private diagnosisService: DiagnosisService,
    private localeService: LocaleService,
    private changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private titleService: Title,
    private notificationsService: NotificationsService,
    private patientService: PatientService,
    private doctorService: DoctorService,
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 768px)');
    this._mobileQueryListener = () => this.changeDetectorRef.detectChanges();
    this.mobileQuery.addEventListener("change", this._mobileQueryListener);
  }

  async ngOnInit(): Promise<void> {
    const callId = this.sharedDataService.getCall();
    if (!callId) this.router.navigate([AppPaths.MESSAGE], {state: {message: "malformed_url_error"}});

    this.titleService.setTitle(this.localeService.getString("title"));

    await this.getCall(callId);

    const [patientCalls, canPrescribeMedication, canMakeMedicalOrderInstruction] = await Promise.all([
      this.callService.getPatientCalls(callId, 0, 10, true),
      this.doctorService.getCanPrescribeMedication(this.call.doctor.id, this.call.id),
      this.doctorService.getCanMakeMedicalOrderInstruction(this.call.doctor.id),
      this.getPatientFiles(),
      this.getCie10(),
      this.localeService.usingDefault() ? this.localeService.use(this.call.provider.language) : true,
    ]);

    if(patientCalls && !_.isEmpty(patientCalls)) this.patientCalls = patientCalls as {data: Call[], total: number};
    this.setPreviousRecords();
    this.canPrescribeMedication = canPrescribeMedication;
    this.canMakeMedicalOrderInstruction = canMakeMedicalOrderInstruction;

    this.diagnosisComplete = !_.isUndefined(this.call.diagnosis);
    this.sharedDataService.diagnosisComplete$.subscribe(data => {
      this.diagnosisComplete = !!(this.call.diagnosis || data);
    });

    this.loading = false;
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeEventListener("change", this._mobileQueryListener);
  }

  async getCall(callId?: string, duplicatedDiagnosis?: boolean): Promise<void> {
    callId = callId || this.sharedDataService.getCall();
    try {
      this.call = await this.callService.getOne(callId, ['provider.country']);
      if(duplicatedDiagnosis && this.call?.diagnosis)
        this.sharedDataService.diagnosisComplete = true;
    } catch(err) {
      let msg = "malformed_url_error";
      if(err.error && err.error.name === "tokenValidationError") msg = "call_get_error";
      this.router.navigate([AppPaths.MESSAGE], {state: {message: msg}});
    }
  }

  async getCie10(): Promise<void> {
    await this.diagnosisService.getCie10().catch(err => {
      const error = err.error || err;
      this.notificationsService.openErrorDialog({
        message: "api_error",
        error: error,
        actions: [{
          function: () => { this.getCie10(); },
          message: "retry"
        }]
      }, true);
    })
  }

  async getPatientFiles(): Promise<void> {
    await this.patientService.getPatientFilesByDate(this.call.patient.id).then((data) => {
      this.filesByDates = this.insertTypeDataToFiles(data);
    });
  }

  insertTypeDataToFiles(array: MedicalExamDateInfo[]): MedicalExamDateInfo[] {
    array.forEach(item => {
      item.files.map(file => {
        file.typeData = _.find(MEDICAL_EXAM_TYPES, (el) => file.type === el.value);
      })
    });
    return array;
  }

  setPreviousRecords(): void {
    if(!this.call?.records || _.isEmpty(this.call.records)) {
      for(const c of this.patientCalls?.data) {
        if(!_.isEmpty(c.records)) {
          this.previousRecords = c.records;
          break;
        }
      }
    }
  }

  nextStep(): void {
    this.tabGroupInfoIndex ++;
  }
}
