<form *ngIf="formGroup" [formGroup]="formGroup">
  <ng-container *ngIf="usePrescrypto">
    <p>
      {{ 'prescrypto_following_link' | locale }}
      <a class="accent-link" style="font-weight: 500" [href]="prescryptoUrl" target="_blank">Prescrypto</a>.
      {{ 'prescrypto_new_tab' | locale }}
    </p>
    <p>{{ 'prescrypto_complete_call' | locale }}</p>
  </ng-container>

  <ng-container *ngIf="!usePrescrypto">
    <app-medicine-form
      [call]="call"
      [resetObservable]="resetSubject.asObservable()"
      (onSelectMedicine)="onSelectMedicine($event)"
      (onChangeQuantity)="checkProlongedTreatment($event)"
      (validFormChange)="checkIsValidMedicineForm($event)"
      (onSubmitForm)="addMedicinePrescription($event)"
    >
    </app-medicine-form>

    <ng-container *ngIf="prescriptions.controls?.length > 0">
      <h2>{{ 'prescription_other_medicines' | locale }}</h2>
      <mat-divider></mat-divider>

      <app-item
        *ngFor="let medicineFormGroup of prescriptions?.controls; index as index"
        [itemText]="medicineFormGroup.get('medicine')?.value?.active"
        [south]="true"
        iconName="capsule"
        color="bg-primary"
        [collapsable]="true"
        [itemDetailTemplate]="prescripted"
        [data]="medicineFormGroup"
        [index]="index"
      >
      </app-item>

      <ng-template #prescripted let-open="openCollapsable" let-medicineFormGroup="data" let-index="index">
        <div class="collapsable" *ngIf="open">
          <app-medicine-form
            [formGroup]="medicineFormGroup"
            [index]="index"
            [displaySubmitButton]="false"
            (onSelectMedicine)="onSelectMedicine($event)"
            (onChangeQuantity)="checkProlongedTreatment($event)"
          >
          </app-medicine-form>
        </div>
      </ng-template>
    </ng-container>
  </ng-container>

  <mat-checkbox [formControl]="isPrescription" color="primary">{{ 'is_prescription' | locale }}</mat-checkbox>
  <mat-checkbox *ngIf="!usePrescrypto" [formControl]="prolongedTreatment" color="primary">{{
    'prolonged_treatment' | locale
  }}</mat-checkbox>
  <mat-checkbox *ngIf="!usePrescrypto" [formControl]="sendDuplicates" color="primary">{{ 'is_duplicate' | locale }}</mat-checkbox>

  <div class="actions">
    <button
      type="submit"
      mat-flat-button
      color="primary"
      (click)="next()"
      [disabled]="
        !usePrescrypto &&
        (!prescriptions.valid ||
          (prescriptions?.length == 0 && !isMedicineFormValid) ||
          (prescriptions?.length > 0 && !isMedicineFormValid && hasSomeFilledFields) ||
          (!isMedicineFormValid && prescriptions?.length === call.prescriptions?.length))
      "
    >
      {{ (usePrescrypto ? 'continue' : 'prescription_send') | locale }}
      <mat-icon svgIcon="arrow-right" class="east"></mat-icon>
    </button>

    <button mat-flat-button color="primary" (click)="skipStep()">
      {{ 'prescription_skip' | locale }}
      <mat-icon svgIcon="arrow-right" class="east"></mat-icon>
    </button>
  </div>
</form>
